import { useAuth0 } from "@auth0/auth0-react"
import * as Sentry from "@sentry/react"
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import { config } from "@/config"
import {
    AnalyticsDateRange,
    ClientCredentials,
    CreateMerchant,
    CreateMerchantUser,
    EditMerchantRole,
    InviteUserResponse,
    MCFList,
    McfSalesChannel,
    Merchant,
    MerchantConfig,
    MerchantMcf,
    MerchantMcfAuthSettings,
    MerchantMcfList,
    MerchantMCFSalesChannelConfig,
    MerchantMCFSalesChannelConn,
    MerchantRole,
    MerchantsFilters,
    MerchantUser,
    Order,
    OrderCancellationRateResponse,
    OrderHistoryResponse,
    OrderProcessingTimeResponse,
    OrdersFilters,
    OrderVolumeResponse,
    PaginatedResponse,
    SalesChannel,
    ZMCFSchedulesRequest,
} from "@/services/types"

interface ErrorResponse {
    type: string
    status: number
    detail: string
    field_name: string | null
}

export class ApiError extends Error {
    constructor(
        message: string,
        public readonly response?: ErrorResponse,
        public readonly status?: number,
    ) {
        super(message)
        this.name = "ApiError"
        // Send to Sentry
        Sentry.captureException(this, {
            extra: {
                response: this.response,
                status: this.status,
            },
        })
    }
}

// Type guard to check if error is an instance of ApiError
export const isApiError = (error: Error): error is ApiError => {
    return error instanceof ApiError
}

export const is404Error = (error: Error): boolean => {
    return isApiError(error) && error.status === 404
}

const filtersToParams = (filters: Record<string, any>): URLSearchParams => {
    const params = new URLSearchParams()
    Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            if (Array.isArray(value)) {
                value.forEach((v) => params.append(key, v.toString()))
            } else {
                params.append(key, value.toString())
            }
        }
    })
    return params
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${config.settings.API_URL}/api`,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
    },
})

const makeAuthenticatedApiCall = async <T>(
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    url: string,
    getAccessTokenSilently: () => Promise<string>,
    config: Partial<AxiosRequestConfig> = {},
): Promise<T> => {
    try {
        const token = await getAccessTokenSilently()
        const requestConfig: AxiosRequestConfig = {
            ...config,
            method,
            url,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            },
        }
        const response = await axiosInstance(requestConfig)
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<ErrorResponse>
            const errorResponse = axiosError.response?.data
            throw new ApiError(
                errorResponse?.detail || axiosError.message || "An error occurred",
                errorResponse,
                axiosError.response?.status,
            )
        }
        throw new ApiError("An unexpected error occurred")
    }
}

type ClientCredentialResponse = { data: ClientCredentials[] }

export const useAuthenticatedApi = () => {
    const { getAccessTokenSilently } = useAuth0()

    return {
        getMerchants: (filters: MerchantsFilters) => {
            return async (): Promise<PaginatedResponse<Merchant[]>> => {
                const params = filtersToParams(filters)
                const config: Partial<AxiosRequestConfig> = params.toString() ? { params } : {}
                return await makeAuthenticatedApiCall<PaginatedResponse<Merchant[]>>(
                    "GET",
                    `/internal/merchants`,
                    getAccessTokenSilently,
                    config,
                )
            }
        },

        createMerchant: async (data: CreateMerchant): Promise<CreateMerchant> => {
            return await makeAuthenticatedApiCall<CreateMerchant>("POST", `/internal/merchants`, getAccessTokenSilently, {
                data,
                timeout: 60000, // Long backend call, because it creates objects in Auth0
            })
        },

        getMerchantDetails: (merchantCode: string) => {
            return async (): Promise<MerchantConfig> => {
                return await makeAuthenticatedApiCall<MerchantConfig>("GET", `/internal/merchants/${merchantCode}`, getAccessTokenSilently)
            }
        },

        updateMerchantDetails: async (merchantCode: string, data: MerchantConfig) => {
            return await makeAuthenticatedApiCall<MerchantConfig>("PUT", `/internal/merchants/${merchantCode}`, getAccessTokenSilently, {
                data,
            })
        },

        getMerchantUsers: (merchantCode: string) => {
            return async (): Promise<PaginatedResponse<MerchantUser[]>> => {
                return await makeAuthenticatedApiCall<PaginatedResponse<MerchantUser[]>>(
                    "GET",
                    `/internal/merchants/${merchantCode}/users`,
                    getAccessTokenSilently,
                )
            }
        },

        updateMerchantUser: async (merchantCode: string, userId: string, data: EditMerchantRole) => {
            return await makeAuthenticatedApiCall<void>(
                "PATCH",
                `/internal/merchants/${merchantCode}/users/${userId}`,
                getAccessTokenSilently,
                { data },
            )
        },

        deleteMerchantUser: async (merchantCode: string, userId: string) => {
            return await makeAuthenticatedApiCall<void>(
                "DELETE",
                `/internal/merchants/${merchantCode}/users/${userId}`,
                getAccessTokenSilently,
            )
        },

        createUsersInvite: async (merchantCode: string, data: CreateMerchantUser): Promise<string> => {
            const response = await makeAuthenticatedApiCall<InviteUserResponse>(
                "POST",
                `/internal/merchants/${merchantCode}/users/invite`,
                getAccessTokenSilently,
                {
                    // backend supports a list of users, but we only send one user at a time
                    data: [data],
                    timeout: 60000, // Long backend call, because it creates objects in Auth0
                },
            )
            if (response.failed_invites.length) {
                throw new ApiError(
                    "Failed to invite user",
                    {
                        detail: response.failed_invites[0].message,
                        status: response.failed_invites[0].statusCode,
                        field_name: null,
                        type: "server_error",
                    },
                    response.failed_invites[0].statusCode,
                )
            }
            return response.successful_invites[0]
        },

        getMerchantRoles: () => {
            return async (): Promise<MerchantRole[]> => {
                return await makeAuthenticatedApiCall<MerchantRole[]>("GET", `/internal/merchant-roles`, getAccessTokenSilently)
            }
        },

        getClientCredentials: (merchantCode: string) => {
            return async (): Promise<ClientCredentials> => {
                const response = await makeAuthenticatedApiCall<ClientCredentialResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/auth/client-credentials`,
                    getAccessTokenSilently,
                )
                return response.data[0]
            }
        },

        rotateClientSecret: async (merchantCode: string, clientId: string) => {
            return await makeAuthenticatedApiCall<ClientCredentials>(
                "POST",
                `/internal/merchants/${merchantCode}/auth/client-credentials/${clientId}/rotate-secret`,
                getAccessTokenSilently,
            )
        },

        getOrders: (merchantCode: string, filters: OrdersFilters) => {
            return async (): Promise<PaginatedResponse<Order[]>> => {
                const params = filtersToParams(filters)
                const config: Partial<AxiosRequestConfig> = params.toString() ? { params } : {}

                return await makeAuthenticatedApiCall<PaginatedResponse<Order[]>>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-orders`,
                    getAccessTokenSilently,
                    config,
                )
            }
        },

        getOrderDetails: (merchantCode: string, orderId: string) => {
            return async (): Promise<Order> => {
                return await makeAuthenticatedApiCall<Order>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-orders/${orderId}`,
                    getAccessTokenSilently,
                )
            }
        },

        getOrderVolume: (merchantCode: string, dateRange: AnalyticsDateRange) => {
            return async (): Promise<OrderVolumeResponse> => {
                return await makeAuthenticatedApiCall<OrderVolumeResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/analytics/order-volume`,
                    getAccessTokenSilently,
                    { params: dateRange },
                )
            }
        },

        getCancellationRate: (merchantCode: string, dateRange: AnalyticsDateRange) => {
            return async (): Promise<OrderCancellationRateResponse> => {
                return await makeAuthenticatedApiCall<OrderCancellationRateResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/analytics/cancellation-rate`,
                    getAccessTokenSilently,
                    { params: dateRange },
                )
            }
        },

        getProcessingTime: (merchantCode: string, dateRange: AnalyticsDateRange) => {
            return async (): Promise<OrderProcessingTimeResponse> => {
                return await makeAuthenticatedApiCall<OrderProcessingTimeResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/analytics/processing-time`,
                    getAccessTokenSilently,
                    { params: dateRange },
                )
            }
        },

        getOrderHistory: (merchantCode: string, orderCode: string) => {
            return async (): Promise<OrderHistoryResponse> => {
                return await makeAuthenticatedApiCall<OrderHistoryResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-orders/${orderCode}/history`,
                    getAccessTokenSilently,
                )
            }
        },

        // Fetch available fulfillment networks
        getMcfs: () => {
            return async (): Promise<MCFList[]> => {
                return await makeAuthenticatedApiCall<MCFList[]>("GET", `/internal/mcfs`, getAccessTokenSilently)
            }
        },

        // Get Fulfillment Networks of a Merchant
        getMerchantMcfs: (merchantCode: string) => {
            return async (): Promise<MerchantMcfList[]> => {
                return await makeAuthenticatedApiCall<MerchantMcfList[]>(
                    "GET",
                    `/internal/merchants/${merchantCode}/mcfs`,
                    getAccessTokenSilently,
                )
            }
        },

        // Get Fulfillment Network config for merchant
        getMerchantMcfConfig: (merchantCode: string, mcfId: number) => {
            return async (): Promise<MerchantMcf> => {
                return await makeAuthenticatedApiCall<MerchantMcf>(
                    "GET",
                    `/internal/merchants/${merchantCode}/mcfs/${mcfId}`,
                    getAccessTokenSilently,
                )
            }
        },

        // Create a new fulfillment network for a merchant
        createMerchantMcf: async (merchantCode: string, mcfId: number, data?: MerchantMcf) => {
            return await makeAuthenticatedApiCall<MerchantMcf>(
                "POST",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}`,
                getAccessTokenSilently,
                { data },
            )
        },

        // Update fulfillment network configuration for a merchant
        updateMerchantMcfConfig: async (merchantCode: string, mcfId: number, data: MerchantMcf) => {
            return await makeAuthenticatedApiCall<MerchantMcf>(
                "PUT",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}`,
                getAccessTokenSilently,
                { data },
            )
        },

        // Get Fulfillment Network Auth Settings for a merchant
        getMerchantMcfAuthSettings: (merchantCode: string, mcfId: number) => {
            return async (): Promise<MerchantMcfAuthSettings> => {
                return await makeAuthenticatedApiCall<MerchantMcfAuthSettings>(
                    "GET",
                    `/internal/merchants/${merchantCode}/mcfs/${mcfId}/auth-settings`,
                    getAccessTokenSilently,
                )
            }
        },

        // Create new MCF auth settings for a merchant
        createMerchantMcfAuthSettings: async (merchantCode: string, mcfId: number, data: MerchantMcfAuthSettings) => {
            return await makeAuthenticatedApiCall<MerchantMcfAuthSettings>(
                "POST",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}/auth-settings`,
                getAccessTokenSilently,
                { data },
            )
        },

        // Update existing MCF auth settings for a merchant
        updateMerchantMcfAuthSettings: async (merchantCode: string, mcfId: number, data: MerchantMcfAuthSettings) => {
            return await makeAuthenticatedApiCall<MerchantMcfAuthSettings>(
                "PUT",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}/auth-settings`,
                getAccessTokenSilently,
                { data },
            )
        },

        getSalesChannels: (merchantCode: string) => {
            return async (): Promise<SalesChannel[]> => {
                return await makeAuthenticatedApiCall<SalesChannel[]>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-channels`,
                    getAccessTokenSilently,
                )
            }
        },

        // Get Fulfillment Network sales channels
        getMcfSalesChannels: (mcfId: number) => {
            return async (): Promise<McfSalesChannel[]> => {
                return await makeAuthenticatedApiCall<McfSalesChannel[]>(
                    "GET",
                    `/internal/mcfs/${mcfId}/sales-channels`,
                    getAccessTokenSilently,
                )
            }
        },

        // Get merchant-specific channels for the Merchant
        getMerchantMcfSalesChannels: (merchantCode: string, mcfId: number) => {
            return async (): Promise<MerchantMCFSalesChannelConn[]> => {
                return await makeAuthenticatedApiCall<MerchantMCFSalesChannelConn[]>(
                    "GET",
                    `/internal/merchants/${merchantCode}/mcfs/${mcfId}/sales-channels`,
                    getAccessTokenSilently,
                )
            }
        },

        // Get a specific sales channel configuration
        getMerchantMcfSalesChannelConfig: (merchantCode: string, mcfId: number, mcfSalesChannelId: number) => {
            return async (): Promise<MerchantMCFSalesChannelConfig> => {
                return await makeAuthenticatedApiCall<MerchantMCFSalesChannelConfig>(
                    "GET",
                    `/internal/merchants/${merchantCode}/mcfs/${mcfId}/sales-channels/${mcfSalesChannelId}`,
                    getAccessTokenSilently,
                )
            }
        },

        // Create a new sales channel configuration
        createMerchantMcfSalesChannel: async (
            merchantCode: string,
            mcfId: number,
            mcfSalesChannelId: number,
            data: MerchantMCFSalesChannelConfig,
        ): Promise<MerchantMCFSalesChannelConfig> => {
            return await makeAuthenticatedApiCall<MerchantMCFSalesChannelConfig>(
                "POST",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}/sales-channels/${mcfSalesChannelId}`,
                getAccessTokenSilently,
                { data },
            )
        },

        // Update an existing sales channel configuration
        updateMerchantMcfSalesChannel: async (
            merchantCode: string,
            mcfId: number,
            mcfSalesChannelId: number,
            data: MerchantMCFSalesChannelConfig,
        ): Promise<MerchantMCFSalesChannelConfig> => {
            return await makeAuthenticatedApiCall<MerchantMCFSalesChannelConfig>(
                "PUT",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}/sales-channels/${mcfSalesChannelId}`,
                getAccessTokenSilently,
                { data },
            )
        },

        deleteMerchantMcfSalesChannel: async (merchantCode: string, mcfId: number, mcfSalesChannelId: number): Promise<void> => {
            return await makeAuthenticatedApiCall<void>(
                "DELETE",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}/sales-channels/${mcfSalesChannelId}`,
                getAccessTokenSilently,
            )
        },

        // Get Fulfillment Network schedule configuration for a merchant
        getMerchantMcfSchedules: (merchantCode: string, mcfId: number) => {
            return async (): Promise<ZMCFSchedulesRequest> => {
                return await makeAuthenticatedApiCall<ZMCFSchedulesRequest>(
                    "GET",
                    `/internal/merchants/${merchantCode}/mcfs/${mcfId}/schedules`,
                    getAccessTokenSilently,
                )
            }
        },

        // Create or update schedule configuration for a merchant's Fulfillment Network
        updateMerchantMcfSchedules: async (
            merchantCode: string,
            mcfId: number,
            data: ZMCFSchedulesRequest,
        ): Promise<ZMCFSchedulesRequest> => {
            return await makeAuthenticatedApiCall<ZMCFSchedulesRequest>(
                "POST",
                `/internal/merchants/${merchantCode}/mcfs/${mcfId}/schedules`,
                getAccessTokenSilently,
                { data },
            )
        },
    }
}
