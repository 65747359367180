import { FC } from "react"
import { CrossOctagonFilledIcon, SuccessIcon } from "@partner-tech/pax-icons"
import { Snackbar, SnackbarProps } from "@partner-tech/pax-ui-kit"
import cn from "classnames"

type Props = {
    open: boolean
    message: string
    type?: SnackbarProps["type"]
    onClose: () => void
    autoHideDuration: SnackbarProps["autoHideDuration"]
}
export const ToastMessage: FC<Props> = ({ open, message, type = "error", onClose, autoHideDuration }) => {
    const ToastIcon = type === "success" ? SuccessIcon : CrossOctagonFilledIcon

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            ContentProps={{
                className: cn("!text-semantic-text-primary !border-0 shadow-md flex-nowrap", {
                    "!bg-semantic-background-positive": type === "success",
                    "!bg-semantic-background-critical": type === "error",
                }),
            }}
            message={
                <div className={"flex flex-row items-center gap-4"}>
                    <ToastIcon size={24} className={type === "success" ? "text-semantic-ui-positive" : "text-semantic-ui-critical"} />
                    {message}
                </div>
            }
        />
    )
}
